import Sidebar from '../Sidebar'
import { Outlet } from 'react-router-dom'
import './index.scss'

const Layout = () => {
  return (
    <div className="App">
      <Sidebar />
      <div className="page">
        <span className="tags top-tags">&lt;caseyescovedo&gt;</span>
        <Outlet />
        <span className="tags bottom-tags">&lt;/caseyescovedo&gt;</span>
        <br />
        {/* <span className="bottom-tag-html">&lt;/html&gt;</span> */}
      </div>
    </div>
  )
}

export default Layout
