// import CaseyLogo from '../../assets/images/caseylogo.png'
import { Link } from 'react-router-dom'
import './index.scss'

let dateToday = new Date()

let hr = dateToday.getHours()
let greeting = 'hello'

if (hr < 5) {
  greeting = "Wow, it's late"
} else if (hr < 12) {
  greeting = 'Good morning'
} else if (hr >= 12 && hr < 17) {
  greeting = 'Good afternoon'
} else {
  greeting = 'Good evening'
}

const Home = () => {
  return (
    <div className="container home-page">
      <div className="text-zone">
        <h1>
          {greeting}, <br /> I'm Casey,
          <br />
          software engineer
        </h1>
        <h2>music producer</h2>
        <h2>tech entrepreneur</h2>
        <h2>amateur golfer</h2>
        <Link to="/contact" className="flat-button">
          CONTACT ME
        </Link>
      </div>
    </div>
  )
}

export default Home
